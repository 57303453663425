import React from 'react'
import { Link } from 'gatsby'
import {connectStateResults} from 'react-instantsearch-dom'

const StateResults = ({searchState, searchResults, showMore}) => {
  const hasResults = searchResults && searchResults.nbHits !== 0
  const nbHits = searchResults && searchResults.nbHits

  return (
    <div>
      <div hidden={hasResults} className='text-center text-sm p-4'>Fant ingen treff.</div>
      <div hidden={!hasResults} className="flex justify-center items-center mt-2">
        {nbHits > 5 && showMore && (<Link to={`/search?query=${searchState.query || ''}`} className="uppercase p-4 font-bold text-xs md:text-sm underline-offset-4 text-primary">Vis alle {nbHits.toLocaleString()} treff</Link>)}
      </div>
    </div>
  )
}

export default connectStateResults(StateResults)
