import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

export default connectSearchBox(
  ({ refine, currentRefinement, className, size, onFocus }) => (
    <form className={className} action="/search" method="GET" autoComplete="off">
      <div className="relative w-auto">
        <input
          className={size === 'large' ? 'inline-block p-4 lg:px-8 text-lg text-primary rounded-full w-full outline-none' : 'inline-block p-2 lg:p-3 px-3 lg:px-6 lg:w-72 text-base text-primary rounded-full w-full max-w-xs sm:max-w-xs outline-none'}
          type="search"
          placeholder="Hva ser du etter?"
          aria-label="Search"
          name="query"
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
          onFocus={onFocus}
        />
        <button type="submit" className={size === 'large' ? 'absolute top-0 right-0 p-4 text-primary' : 'absolute top-0 right-0 p-3 sm:pr-4 text-primary'}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className={size === 'large' ? 'w-6 h-6 mt-px' : 'w-4 h-4 sm:w-5 sm:h-5 mt-px'}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>    
        </button>
      </div>
    </form>
  )
)