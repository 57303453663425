import React from 'react'
import {connectRefinementList} from 'react-instantsearch-dom'

const RefinementList = ({ items, refine, searchForItems }) => (
  <ul>
    {items.map(item => (
      <li key={item.label}>
        <a
          href="#"
          className={`${item.isRefined ? 'bg-primary text-white' : 'bg-white text-black'} inline-block p-3 px-4 no-underline rounded-full mb-2 capitalize`}
          onClick={event => {
            event.preventDefault();
            refine(item.value);
          }}
        >
          {item.label} ({item.count})
        </a>
      </li>
    ))}
  </ul>
)

export default connectRefinementList(RefinementList)
