import { createRef, default as React, useState, useRef, useEffect, useMemo } from "react"
import { Link } from 'gatsby'
import algoliasearch from "algoliasearch/lite"
import { navigate } from "@reach/router"  
import qs from 'qs'
import { format } from 'date-fns'
import nb from 'date-fns/locale/nb'
import { 
  InstantSearch,
  Hits,
  Pagination,
  Configure
} from "react-instantsearch-dom"
import SEO from '../components/seo'
import SearchBox from "../components/search-box"
import SearchStats from "../components/search-stats"
import SearchRefinementList from "../components/search-refinement-list"
import StateResults from "../components/search-state-results"
import useClickOutside from "../components/use-click-outside"
import faqImage from '../images/faq.jpg'

const Hit = (props) => {
  return (
    <Link to={props.hit.href} className="flex justify-start -ml-4 -mr-4 lg:ml-0 lg:mr-0 p-4 lg:p-6 hover:bg-purple-light no-underline mb-2">
      <div className="mr-4 lg:mr-8">
        <figure className="w-24 lg:w-48 flex-shrink-0 block bg-purple-light aspect-ratio-square relative">
          {props.hit.image && (
          <img src={props.hit.image} alt="" className="absolute top-0 left-0" />
          )}
          {!props.hit.image && props.hit.type === 'FAQ' && (
            <img src={faqImage} alt="" className="absolute top-0 left-0" />
          )}
        </figure>
      </div>
      <div>
        <div className="font-serif font-semibold text-lg md:text-2xl text-primary flex items-start">
          <span>{props.hit.title}</span>
          {props.hit.type === 'Tjeneste' && (
          <span className="text-white bg-primary rounded-sm p-1 px-2 uppercase font-bold inline-block font-sans text-xs ml-3 mt-1">Bestill nå</span>
          )}
        </div>
        <div className="text-xs text-primary-light mb-1 md:mb-3">
          <span className="inline-block">{props.hit.type}</span>
          {props.hit.createdAt && props.hit.type == 'Bloggartikkel' && (
            <span className="inline-block"><span className="inline-block mx-1">&bull;</span>Skrevet {format(new Date(props.hit.createdAt), 'Do MMMM YYYY', { locale: nb })}</span>
          )}
        </div>
        
        <div className="hit-description text-sm md:text-base">
          {props.hit.body && props.hit.body.length > 175 ? props.hit.body.substring(0, 175) + '...' : props.hit.body }
        </div>

        {props.hit.categoryTitle && (<span className="mt-2 uppercase tracking-wide label text-red bg-red-lighter inline-block rounded-sm py-1 px-2 text-xs">
          {props.hit.categoryTitle}
        </span>)}
      </div>
    </Link>
  );
}

const Search = (props) => {
  const rootRef = createRef()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  useClickOutside(rootRef, () => setFocus(false))

  const location = props.location
  const DEBOUNCE_TIME = 400

  const createURL = state => `/search?${qs.stringify(state)}`;
  const searchStateToUrl = searchState => searchState ? createURL(searchState) : ''
  const urlToSearchState = ({ search }) => qs.parse(search.slice(1))

  const [searchState, setSearchState] = useState(urlToSearchState(location))
  const debouncedSetStateRef = useRef(null);

  const onSearchStateChange = (updatedSearchState) => {
    clearTimeout(debouncedSetStateRef.current)

    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(updatedSearchState))
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState)
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location))
  }, [location]);

  const [mobileFiltersVisible, setMobileFiltersVisible] = useState(false)

  return (
  <div>
    <SEO title='Søk' />
    <div ref={rootRef}>
      <InstantSearch
        searchClient={searchClient}
        indexName="content"
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Configure
          hitsPerPage={20}
        />
        <div className='bg-peach-lighter header-offset'>
          <div className='text-center md:max-w-2xl py-4 md:pt-8 md:pb-12 mx-auto px-5'>
            {!searchState.query && (
            <div className="hidden md:block font-serif font-semibold text-lg sm:text-3xl text-primary mb-4">Hva ser du etter?</div>
            )}
            {searchState.query && (
            <div className="font-serif font-semibold text-lg sm:text-3xl text-primary mb-4">Viser <SearchStats /> for "{searchState.query}"</div>
            )}
            <SearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} size="large" className="relative md:max-w-lg mx-auto" />
          </div>
        </div>
        <div className='bg-purple-lighter'>
          <div className="grid md:grid-cols-6 sm:gap-4 lg:gap-8 sm:py-6 lg:py-12 px-4 max-w-screen-xl mx-auto">
            <div className="md:col-span-2">
              <div className="flex md:hidden w-full pt-2">
                <button onClick={() => setMobileFiltersVisible(!mobileFiltersVisible)} className="text-xs ml-auto h-10 px-2 uppercase font-bold text-primary">{!mobileFiltersVisible ? 'Vis filtre' : 'Skjul filtre'}</button>
              </div>
              <div className={mobileFiltersVisible ? '' : 'hidden md:block'}>
                <div className="uppercase font-bold text-sm mb-4 text-primary">Filtrér innholdstype</div>
                <SearchRefinementList attribute="type" />
              </div>
            </div>
            <div className='md:col-span-4'>
              <Hits hitComponent={Hit} />
              <StateResults showMore={false} />
              <div className="mt-8 mb-4 lg:px-6">
                <Pagination />
              </div>
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  </div>
  )
}

export default Search
